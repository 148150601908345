/* eslint-disable import/prefer-default-export */

import {
  GetAvailableOfficeDays,
  GetAvailableOfficeDays_getAvailableOfficeDays
} from 'graphql/generated/GetAvailableOfficeDays';
import { createMockedAvailableDays } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/availableDays';

export function mockedGetAvailableDays(
  availableDays: GetAvailableOfficeDays_getAvailableOfficeDays[]
): GetAvailableOfficeDays {
  return {
    getAvailableOfficeDays: availableDays
  };
}

export const mockedGetAvailableDaysDataForQuery = mockedGetAvailableDays([
  createMockedAvailableDays({ date: '2020-03-01' })
]);
