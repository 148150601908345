/* eslint-disable import/prefer-default-export */
import {
  GetLocationsForTenant,
  GetLocationsForTenant_getLocationsForTenant
} from 'graphql/generated/GetLocationsForTenant';
import { createMockedLocation } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/location';

export function mockedGetLocationsForTenantData(
  getLocationsForTenant: Array<GetLocationsForTenant_getLocationsForTenant>
): GetLocationsForTenant {
  return {
    getLocationsForTenant
  };
}

export const mockedGetLocationsForTenantDataForQuery = mockedGetLocationsForTenantData([
  createMockedLocation({ id: 'mockedLocationId' })
]);
