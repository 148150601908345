/* eslint-disable import/prefer-default-export */
import {
  GetDoorsForTenantWithOfficeMode,
  GetDoorsForTenantWithOfficeMode_getDoorsForTenant
} from 'graphql/generated/GetDoorsForTenantWithOfficeMode';
import { createMockedDoorWithOfficeMode } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/doorWithOfficeMode';

export function mockedGetDoorsWithOfficeModeForTenantData(
  getDoorsForTenant: Array<GetDoorsForTenantWithOfficeMode_getDoorsForTenant>
): GetDoorsForTenantWithOfficeMode {
  return {
    getDoorsForTenant
  };
}

export const mockedGetDoorsWithOfficeDaysForTenantDataForQuery = mockedGetDoorsWithOfficeModeForTenantData([
  createMockedDoorWithOfficeMode({
    id: 'mockedDoorId0',
    batteryWarningLevel: 0,
    domSerialNumber: '66',
    name: 'door 0'
  }),
  createMockedDoorWithOfficeMode({
    id: 'mockedDoorId1',
    batteryWarningLevel: 0,
    domSerialNumber: '66',
    name: 'door 1'
  }),
  createMockedDoorWithOfficeMode({
    id: 'mockedDoorId1',
    batteryWarningLevel: 0,
    domSerialNumber: '66',
    name: 'door 1'
  })
]);
