/* eslint-disable import/prefer-default-export */

import {
  GetVisitorGroupsForTenantWithIsOfficeModeEnabled,
  GetVisitorGroupsForTenantWithIsOfficeModeEnabled_getVisitorGroupsForTenant
} from 'graphql/generated/GetVisitorGroupsForTenantWithIsOfficeModeEnabled';
import { createMockedAccessGrantWithIsOfficeModeEnabled } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/accessGrantWithIsOfficeModeEnabled';
import { createMockedVisitorGroupWithIsOfficeModeEnabled } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/visitorGroupWithIsOfficeModeEnabled';

export function mockedGetVisitorGroupsForTenantWithIsOfficeModeEnabledData(
  getVisitorGroupsForTenant: Array<GetVisitorGroupsForTenantWithIsOfficeModeEnabled_getVisitorGroupsForTenant>
): GetVisitorGroupsForTenantWithIsOfficeModeEnabled {
  return {
    getVisitorGroupsForTenant
  };
}

export const mockedGetVisitorGroupsForTenantWithIsOfficeModeEnabledDataForQuery =
  mockedGetVisitorGroupsForTenantWithIsOfficeModeEnabledData([
    createMockedVisitorGroupWithIsOfficeModeEnabled({
      id: 'mockedVisitorGroupId0',
      defaultAccessGrants: [createMockedAccessGrantWithIsOfficeModeEnabled({ id: 'mockedAccessGrantId0' })]
    }),
    createMockedVisitorGroupWithIsOfficeModeEnabled({
      id: 'mockedVisitorGroupId1',
      scheduledAccessGrants: [
        createMockedAccessGrantWithIsOfficeModeEnabled({ id: 'mockedAccessGrantId1', isOfficeModeEnabled: false })
      ]
    })
  ]);
