/* eslint-disable import/prefer-default-export */
import { GetDoorsForTenant, GetDoorsForTenant_getDoorsForTenant } from 'graphql/generated/GetDoorsForTenant';
import { createMockedDoor } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/door';

export function mockedGetDoorsForTenantData(
  getDoorsForTenant: Array<GetDoorsForTenant_getDoorsForTenant>
): GetDoorsForTenant {
  return {
    getDoorsForTenant
  };
}

export const mockedGetDoorsForTenantDataForQuery = mockedGetDoorsForTenantData([
  createMockedDoor({ id: 'mockedDoorId0', batteryWarningLevel: 0, domSerialNumber: '66', name: 'door 0' }),
  createMockedDoor({ id: 'mockedDoorId1', batteryWarningLevel: 0, domSerialNumber: '66', name: 'door 1' }),
  createMockedDoor({ id: 'mockedDoorId2', batteryWarningLevel: 0, domSerialNumber: '66', name: 'door 2' })
]);
