/* eslint-disable import/prefer-default-export */
import { MobileOpCode } from 'graphql/generated/globalTypes';
import {
  GET_DOORS_FOR_TENANT,
  GET_CURRENT_HOST,
  GET_LOCATIONS_FOR_TENANT,
  GET_VISITOR_GROUPS_FOR_TENANT,
  GET_ACCESS_EVENTS_FOR_TENANT,
  GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS,
  GET_VISITOR_GROUPS_FOR_TENANT_WITH_SCHEDULES,
  GET_AVAILABLE_OFFICE_DAYS,
  GET_OFFICE_DAYS,
  GET_DOORS_FOR_TENANT_WITH_OFFICE_MODE,
  GET_VISITOR_GROUPS_WITH_IS_ADMIN_GROUP,
  GET_VISITOR_GROUPS_FOR_TENANT_WITH_IS_OFFICE_MODE_ENABLED,
  GET_LOCATIONS_WITH_DOORS_FOR_TENANT,
  GET_DOORS_FOR_TENANT_WITH_LOCATIONS,
  GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS_AND_ROLE,
  GET_VISITOR_GROUPS_FOR_TENANT_WITH_ACCESS_GRANTS
} from 'graphql/queries';
import dayjs from 'dayjs';
import { getDateWithFormat } from 'utils/DateFormat/DateFormat.util';
import { mockedGetCurrentHostDataForQuery } from '../graphqlApollo/query/getCurrentHost';
import { mockedGetDoorsForTenantDataForQuery } from '../graphqlApollo/query/getDoorsForTenant';
import { mockedGetLocationsForTenantDataForQuery } from '../graphqlApollo/query/getLocationForTenant';
import {
  mockedGetAccessEventsForTenantDataForQueryFirstFetch,
  mockedGetAccessEventsForTenantDataForQuerySecondFetch
} from '../graphqlApollo/query/getAccessEventsForTenant';
import { mockedGetVisitorGroupsWithSchedulesForTenantDataForQuery } from '../graphqlApollo/query/getVisitorGroupsForTenantWithSchedules';
import {
  mockedGetVisitorGroupsForTenantWithMembersAndTypesDataForQuery,
  mockedGetVisitorGroupsForTenantWithMembersAndTypesDataForQuery1,
  mockedGetVisitorGroupsForTenantWithMembersAndTypesDataForQueryWithoutFilters
} from '../graphqlApollo/query/getVisitorGroupsForTenantWithMembersAndTypes';
import { mockedGetVisitorGroupsForTenantWithTypesDataForQuery } from '../graphqlApollo/query/getVisitorGroupsForTenantWithTypes';
import { firstDate } from '../graphqlApollo/dateUsedInMocks';
import { mockedGetAvailableDaysDataForQuery } from '../graphqlApollo/query/getAvailableOfficeDays';
import { mockedGetOfficeDaysDataForQuery } from '../graphqlApollo/query/getOfficeDays';
import { mockedGetDoorsWithOfficeDaysForTenantDataForQuery } from '../graphqlApollo/query/getDoorsForTenantWithOfficeMode';
import { mockedGetVisitorGroupsForTenantWithIsAdminGroupDataForQuery } from '../graphqlApollo/query/getVisitorGroupsForTenantWithIsAdminGroupDataForQuery';
import { mockedGetVisitorGroupsForTenantWithIsOfficeModeEnabledDataForQuery } from '../graphqlApollo/query/getVisitorGroupsForTenantWithIsOfficeModeEnabled';
import { mockedGetLocationWithDoorsForTenantDataDataForQuery } from '../graphqlApollo/query/getLocationWithDoorsForTenant';
import { mockedGetDoorsForTenantWithLocationsDataDataForQuery } from '../graphqlApollo/query/getDoorsForTenantWithLocations';
import { mockedGetVisitorGroupsForTenantWithMembersAndRoleDataForQuery } from '../graphqlApollo/query/getVisitorGroupsForTenantWithMembersAndRole';
import { mockedGetVisitorGroupsForTenantWithAccessGrantsDataDataForQuery } from '../graphqlApollo/query/getVisitorGroupsForTenantWithAccessGrants';

export const queries = [
  {
    request: {
      query: GET_OFFICE_DAYS,
      variables: {
        filter: {
          dateRange: {
            rangeStart: getDateWithFormat(
              'AWSDateTime',
              dayjs(firstDate).year(),
              dayjs(firstDate).month(),
              dayjs(firstDate).startOf('month').date()
            ),
            rangeEnd: getDateWithFormat(
              'AWSDateTime',
              dayjs(firstDate).year(),
              dayjs(firstDate).add(1, 'month').month(),
              dayjs(firstDate).endOf('month').date()
            )
          }
        }
      }
    },
    newData: () => ({
      data: mockedGetOfficeDaysDataForQuery
    })
  },
  {
    request: {
      query: GET_AVAILABLE_OFFICE_DAYS,
      variables: {
        from: '2020-03-01',
        to: '2020-04-30'
      }
    },
    newData: () => ({
      data: mockedGetAvailableDaysDataForQuery
    })
  },
  {
    request: {
      query: GET_CURRENT_HOST
    },
    newData: () => ({
      data: mockedGetCurrentHostDataForQuery
    })
  },
  {
    request: {
      query: GET_DOORS_FOR_TENANT
    },
    newData: () => ({
      data: mockedGetDoorsForTenantDataForQuery
    })
  },
  {
    request: {
      query: GET_DOORS_FOR_TENANT_WITH_OFFICE_MODE
    },
    newData: () => ({
      data: mockedGetDoorsWithOfficeDaysForTenantDataForQuery
    })
  },
  {
    request: {
      query: GET_LOCATIONS_FOR_TENANT
    },
    newData: () => ({
      data: mockedGetLocationsForTenantDataForQuery
    })
  },
  {
    request: {
      query: GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS
    },
    newData: () => ({
      data: mockedGetVisitorGroupsForTenantWithMembersAndTypesDataForQueryWithoutFilters
    })
  },
  {
    request: {
      query: GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS,
      variables: {
        filter: {
          type: 'CO_CONTRACTORS'
        }
      }
    },
    newData: () => ({
      data: mockedGetVisitorGroupsForTenantWithMembersAndTypesDataForQuery
    })
  },
  {
    request: {
      query: GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS_AND_ROLE,
      variables: {
        filter: {
          type: 'CO_CONTRACTORS'
        }
      }
    },
    newData: () => ({
      data: mockedGetVisitorGroupsForTenantWithMembersAndRoleDataForQuery
    })
  },
  {
    request: {
      query: GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS,
      variables: {
        filter: {
          type: 'INSTALLERS'
        }
      }
    },
    newData: () => ({
      data: mockedGetVisitorGroupsForTenantWithMembersAndTypesDataForQuery1
    })
  },
  {
    request: {
      query: GET_VISITOR_GROUPS_WITH_IS_ADMIN_GROUP,
      variables: {
        filter: {
          type: 'AZURE_AD'
        }
      }
    },
    newData: () => ({
      data: mockedGetVisitorGroupsForTenantWithIsAdminGroupDataForQuery
    })
  },
  {
    request: {
      query: GET_VISITOR_GROUPS_FOR_TENANT_WITH_SCHEDULES
    },
    newData: () => ({
      data: mockedGetVisitorGroupsWithSchedulesForTenantDataForQuery
    })
  },
  {
    request: {
      query: GET_VISITOR_GROUPS_FOR_TENANT
    },
    newData: () => ({
      data: mockedGetVisitorGroupsForTenantWithTypesDataForQuery
    })
  },
  {
    request: {
      query: GET_ACCESS_EVENTS_FOR_TENANT,
      variables: { filter: { actions: [MobileOpCode.ACCESS_DENIED, MobileOpCode.ACCESS_GRANTED] } }
    },
    newData: () => ({
      data: mockedGetAccessEventsForTenantDataForQueryFirstFetch
    })
  },
  {
    request: {
      query: GET_ACCESS_EVENTS_FOR_TENANT,
      variables: {
        nextToken: 'mockedNextToken',
        filter: { actions: [MobileOpCode.ACCESS_DENIED, MobileOpCode.ACCESS_GRANTED] }
      }
    },
    newData: () => ({
      data: mockedGetAccessEventsForTenantDataForQuerySecondFetch
    })
  },
  {
    request: {
      query: GET_VISITOR_GROUPS_FOR_TENANT_WITH_IS_OFFICE_MODE_ENABLED
    },
    newData: () => ({
      data: mockedGetVisitorGroupsForTenantWithIsOfficeModeEnabledDataForQuery
    })
  },
  {
    request: {
      query: GET_LOCATIONS_WITH_DOORS_FOR_TENANT
    },
    newData: () => ({
      data: mockedGetLocationWithDoorsForTenantDataDataForQuery
    })
  },
  {
    request: {
      query: GET_DOORS_FOR_TENANT_WITH_LOCATIONS
    },
    newData: () => ({
      data: mockedGetDoorsForTenantWithLocationsDataDataForQuery
    })
  },
  {
    request: {
      query: GET_VISITOR_GROUPS_FOR_TENANT_WITH_ACCESS_GRANTS
    },
    newData: () => ({
      data: mockedGetVisitorGroupsForTenantWithAccessGrantsDataDataForQuery
    })
  }
];
