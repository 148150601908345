/* eslint-disable import/prefer-default-export */

import {
  GetVisitorGroupsForTenantWithMembersAndRole,
  GetVisitorGroupsForTenantWithMembersAndRole_getVisitorGroupsForTenant
} from 'graphql/generated/GetVisitorGroupsForTenantWithMembersAndRole';
import { Role } from 'graphql/generated/globalTypes';
import { createMockedMobileDevice } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/mobileDevice';
import { createMockedVisitorGroupMembership } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/visitorGroupMembership';
import { createMockedVisitorGroupWithMembersAndRoleCoContractors } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/visitorGroupWithMembersAndRoleCoContractors';
import { createMockedVisitorWithVisitorGroupMembership } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/visitorWithVisitorGroupMembership';

export function getVisitorGroupsForTenantWithMembersAndRoleDataForQuery(
  getVisitorGroupsForTenant: Array<GetVisitorGroupsForTenantWithMembersAndRole_getVisitorGroupsForTenant>
): GetVisitorGroupsForTenantWithMembersAndRole {
  return {
    getVisitorGroupsForTenant
  };
}

export const mockedGetVisitorGroupsForTenantWithMembersAndRoleDataForQuery =
  getVisitorGroupsForTenantWithMembersAndRoleDataForQuery([
    createMockedVisitorGroupWithMembersAndRoleCoContractors({
      id: 'mockedVisitorGroupIdExternalUsers1',
      members: [
        createMockedVisitorWithVisitorGroupMembership({
          id: 'mockedVisitorIdExternalUser1',
          name: 'mockedVisitorNameExternalUser1',
          email: 'mockedVisitorEmailExternalUser1',
          mobileDevices: [createMockedMobileDevice({ id: 'mockedMobileDevice', isRegistered: false })],
          isEditable: true,
          visitorGroupMembership: [
            createMockedVisitorGroupMembership({
              visitorGroupExternalRef: 'mockedVisitorGroupExternalRefs',
              role: Role.EXTERNAL_GROUP_USER_MANAGER
            })
          ]
        }),
        createMockedVisitorWithVisitorGroupMembership({
          id: 'mockedVisitorIdExternalUser2',
          name: 'mockedVisitorNameExternalUser2',
          email: 'mockedVisitorEmailExternalUser2',
          mobileDevices: [createMockedMobileDevice({ id: 'mockedMobileDevice', isRegistered: false })],
          isEditable: true,
          visitorGroupMembership: [
            createMockedVisitorGroupMembership({ visitorGroupExternalRef: 'mockedVisitorGroupExternalRefs' })
          ]
        })
      ],
      name: 'co-contractors1',
      type: 'CO_CONTRACTORS',
      externalRef: 'mockedVisitorGroupExternalRefs'
    })
  ]);
