import React from 'react';
import styled from 'styled-components';
import Avatar, { Props as AvatarProps } from 'ui/atoms/Avatar/Avatar';
import AvatarSkeleton from 'ui/atoms/Avatar/AvatarSkeleton';
import Icon, { Props as IconProps } from 'ui/atoms/Icon/Icon';
import Checkbox, { Props as CheckboxProps } from 'ui/atoms/Checkbox/Checkbox';
import Switch, { Props as SwitchProps } from 'ui/atoms/Switch/Switch';
import SwitchSkeleton from 'ui/atoms/Switch/SwitchSkeleton';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import { Colors } from 'theme/theme';
import Typography from 'ui/atoms/Typography/Typography';

interface CssProps {
  columnGap?: string;
  backgroundColor?: keyof Colors;
}

export type Props = CssProps & {
  hoverEffect?: boolean;
  hoverAfterIconEffect?: boolean;
  selected?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  avatarProps?: AvatarProps;
  avatarSkeleton?: boolean;
  beforeSwitchProps?: SwitchProps;
  beforeSwitchSkeleton?: boolean;
  afterSwitchProps?: SwitchProps;
  afterSwitchSkeleton?: boolean;
  afterContentIconProps?: Pick<
    IconProps,
    'name' | 'color' | 'width' | 'height' | 'viewBox' | 'onClick' | 'id' | 'stroke'
  >;
  beforeContentIconProps?: Pick<
    IconProps,
    'name' | 'color' | 'width' | 'height' | 'viewBox' | 'onClick' | 'id' | 'stroke'
  >;
  beforeContentText?: string;
  beforeContentTextColor?: keyof Colors;
  beforeContentCheckboxProps?: CheckboxProps;
  hoverArrow?: boolean;
  marginAfterContent?: boolean;
  id?: string;
};

const EmptySpace = styled.div<Record<'size', number>>`
  width: ${({ size }) => `${size}rem`};
`;

const EmptySpaceHoverEffect = styled.div`
  width: 1.5rem;
`;

const EmptyIcon = styled.div<Record<'size', number>>`
  width: ${({ size }) => `${size}rem`};
`;

const HoverArrowWrapper = styled.div`
  margin: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const handleBackGroundColorTableRow = (
  selected: boolean | undefined,
  backgroundColor: keyof Colors | undefined,
  hoverEffect: boolean = false,
  hover: boolean = false
): keyof Colors => {
  if (hoverEffect && hover) return 'b3';
  if (selected) return 'b4';
  if (backgroundColor) return backgroundColor;
  return 'b2';
};

export const TableRowWrapper = styled.div<Partial<Props>>`
  box-sizing: border-box;
  flex: 1 1 100%;
  background-color: ${({ selected, theme, backgroundColor }) =>
    theme.colors[handleBackGroundColorTableRow(selected, backgroundColor)]};
  border: ${({ selected, theme }) =>
    selected ? `1px solid ${theme.colors.primary}` : `1px solid ${theme.colors.transparent}`};
  opacity: ${({ disabled }) => (disabled ? '0.3' : '1')};
  border-radius: 0.25rem;
  display: flex;
  flex-direction: row;
  cursor: ${({ onClick, disabled }) => onClick && !disabled && 'pointer'};

  & ${HoverArrowWrapper} {
    svg {
      display: ${({ hoverArrow }) => hoverArrow && 'none'};
    }
  }

  & ${EmptySpace} {
    display: ${({ hoverArrow }) => hoverArrow && 'flex'};
  }

  & ${IconWrapper} {
    display: ${({ hoverAfterIconEffect }) => hoverAfterIconEffect && 'none'};
  }

  & ${EmptySpaceHoverEffect} {
    display: ${({ hoverAfterIconEffect }) => hoverAfterIconEffect && 'flex'};
  }

  &:hover {
    background-color: ${({ selected, theme, backgroundColor, hoverEffect }) =>
      theme.colors[handleBackGroundColorTableRow(selected, backgroundColor, hoverEffect, true)]};

    & ${HoverArrowWrapper} {
      svg {
        display: ${({ hoverArrow }) => hoverArrow && 'flex'};
      }
    }

    & ${EmptySpace} {
      display: ${({ hoverArrow }) => hoverArrow && 'none'};
    }

    & ${IconWrapper} {
      display: ${({ hoverAfterIconEffect }) => hoverAfterIconEffect && 'flex'};
    }

    & ${EmptySpaceHoverEffect} {
      display: ${({ hoverAfterIconEffect }) => hoverAfterIconEffect && 'none'};
    }
  }
`;

const AvatarWrapper = styled.div`
  margin: 0.8125rem 1rem;
`;

const SwitchWrapper = styled.div`
  margin-left: 1rem;
  display: flex;
  align-items: center;
`;

const ContentWrapper = styled.div<Partial<Props>>`
  height: 3.375rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1 1 100%;
  align-items: center;
  column-gap: ${({ columnGap }) => columnGap};

  ${({ beforeSwitchProps }) =>
    beforeSwitchProps !== undefined
      ? `
    margin: 0 1rem 0 0.75rem;
    `
      : `
    margin: 0 1rem;
    `};
`;

const TableRow: React.FC<Props> = ({
  columnGap = '1rem',
  backgroundColor,
  children,
  hoverEffect,
  hoverAfterIconEffect,
  selected,
  disabled,
  onClick,
  avatarProps,
  avatarSkeleton = false,
  afterSwitchProps,
  afterSwitchSkeleton = false,
  beforeSwitchProps,
  beforeSwitchSkeleton = false,
  beforeContentCheckboxProps,
  afterContentIconProps,
  beforeContentIconProps,
  beforeContentText,
  beforeContentTextColor = 'lTextHigh',
  hoverArrow,
  marginAfterContent = false,
  id
}) => (
  <TableRowWrapper
    backgroundColor={backgroundColor}
    hoverEffect={hoverEffect}
    hoverAfterIconEffect={hoverAfterIconEffect}
    selected={selected}
    disabled={disabled}
    onClick={onClick}
    id={id}
    data-testid={id}
    afterContentIconProps={afterContentIconProps}
    beforeContentIconProps={beforeContentIconProps}
    hoverArrow={hoverArrow}
  >
    {avatarProps && (
      <AvatarWrapper>
        <Avatar {...avatarProps} />
      </AvatarWrapper>
    )}
    {avatarSkeleton && (
      <AvatarWrapper>
        <AvatarSkeleton />
      </AvatarWrapper>
    )}
    {beforeSwitchProps !== undefined && (
      <SwitchWrapper>
        <Switch {...beforeSwitchProps} />
      </SwitchWrapper>
    )}
    {beforeSwitchSkeleton && (
      <SwitchWrapper>
        <SwitchSkeleton />
      </SwitchWrapper>
    )}
    {beforeContentIconProps && (
      <ComponentWrapper margin="0 1rem" alignItems="center" justifyContent="center" flexDirection="column">
        {beforeContentIconProps.name ? (
          <Icon {...beforeContentIconProps} id={`${id}-${beforeContentIconProps.name}-icon`} />
        ) : (
          <EmptyIcon size={1.75} />
        )}
        {beforeContentText && (
          <Typography variant="label" color={beforeContentTextColor}>
            {beforeContentText}
          </Typography>
        )}
      </ComponentWrapper>
    )}
    {beforeContentCheckboxProps && (
      <ComponentWrapper
        margin="0 1rem"
        alignItems="center"
        justifyContent="center"
        id={`${beforeContentCheckboxProps?.id}-div`}
      >
        <Checkbox {...beforeContentCheckboxProps} ref={null} />
      </ComponentWrapper>
    )}
    <ContentWrapper columnGap={columnGap} beforeSwitchProps={beforeSwitchProps}>
      {children}
    </ContentWrapper>
    {afterContentIconProps?.name && (
      <ComponentWrapper margin="0 1rem" alignItems="center" justifyContent="center" onClick={onClick}>
        <IconWrapper>
          <Icon {...afterContentIconProps} />
        </IconWrapper>
        <EmptySpace size={0.5625} />
        {hoverAfterIconEffect && <EmptySpaceHoverEffect />}
      </ComponentWrapper>
    )}
    {afterSwitchProps !== undefined && (
      <SwitchWrapper>
        <Switch {...afterSwitchProps} />
      </SwitchWrapper>
    )}
    {afterSwitchSkeleton && (
      <SwitchWrapper>
        <SwitchSkeleton />
      </SwitchWrapper>
    )}
    {onClick !== undefined && hoverArrow && (
      <HoverArrowWrapper onClick={onClick}>
        <Icon color="transparent" stroke="primary" name="ArrowRightTable" width={9} height={16} id="hover-Arrow" />
        <EmptySpace size={0.5625} />
      </HoverArrowWrapper>
    )}
    {marginAfterContent && <HoverArrowWrapper />}
  </TableRowWrapper>
);

export default TableRow;
