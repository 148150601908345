/* eslint-disable import/prefer-default-export */

import {
  GetVisitorGroupsForTenantWithIsAdminGroup,
  GetVisitorGroupsForTenantWithIsAdminGroup_getVisitorGroupsForTenant
} from 'graphql/generated/GetVisitorGroupsForTenantWithIsAdminGroup';
import { createMockedVisitorGroupWithIsAdminGroup } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/visitorGroupWithIsAdminGroup';

export function getVisitorGroupsForTenantWithIsAdminGroupDataForQuery(
  getVisitorGroupsForTenant: Array<GetVisitorGroupsForTenantWithIsAdminGroup_getVisitorGroupsForTenant>
): GetVisitorGroupsForTenantWithIsAdminGroup {
  return {
    getVisitorGroupsForTenant
  };
}

export const mockedGetVisitorGroupsForTenantWithIsAdminGroupDataForQuery =
  getVisitorGroupsForTenantWithIsAdminGroupDataForQuery([
    createMockedVisitorGroupWithIsAdminGroup({
      id: 'mockedVisitorGroupId1',
      name: 'noAdminGroup',
      isAdminGroup: false,
      externalRef: 'mockedVisitorGroupExternalRef1'
    }),
    createMockedVisitorGroupWithIsAdminGroup({
      id: 'mockedVisitorGroupId2',
      name: 'adminGroup',
      isAdminGroup: true,
      externalRef: 'mockedVisitorGroupExternalRef2'
    })
  ]);
