/* eslint-disable import/prefer-default-export */
import {
  GetDoorsForTenantWithLocations,
  GetDoorsForTenantWithLocations_getDoorsForTenant
} from 'graphql/generated/GetDoorsForTenantWithLocations';
import { createMockedDoorWithLocations } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/doorWithLocations';

export function mockedGetDoorsForTenantWithLocationsData(
  getDoorsForTenant: Array<GetDoorsForTenantWithLocations_getDoorsForTenant>
): GetDoorsForTenantWithLocations {
  return {
    getDoorsForTenant
  };
}

export const mockedGetDoorsForTenantWithLocationsDataDataForQuery = mockedGetDoorsForTenantWithLocationsData([
  createMockedDoorWithLocations({ id: 'mockedDoorId0', batteryWarningLevel: 0, domSerialNumber: '66', name: 'door 0' }),
  createMockedDoorWithLocations({ id: 'mockedDoorId1', batteryWarningLevel: 0, domSerialNumber: '66', name: 'door 1' }),
  createMockedDoorWithLocations({ id: 'mockedDoorId2', batteryWarningLevel: 0, domSerialNumber: '66', name: 'door 2' })
]);
